import { Component, createRef } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import { Dropdown } from "react-bootstrap";

//  Images
import carrentIcon from "include/images/carrent-icon.svg";

//  Components
import Header from "common/header";
import Loader from "common/loader";
import { getTelegram, loadTelegramSettings } from "store/telegram";
import SideBar from "../../../common/sidebar";
import AddMiscSettings from "./addMiscSettings";

class Listing extends Component {
  ref = createRef(null);
  state = {
    miscSettingData: [
      {
        title: "Email Settings",
        value: "email",
        data: [
          {
            key: "withdrawUsdtEmails",
            type: "array",
          },
          {
            key: "fraudDetectionEmails",
            type: "array",
          },
        ],
      },
      // {
      //   title: "Chain Link Settings",
      //   value: "chainLink",
      //   data: [
      //     {
      //       key: "chainlinkVRFCoodinatorAddress",
      //       type: "string",
      //     },
      //     {
      //       key: "chainlinkSubscriptionId",
      //       type: "number",
      //     },
      //     {
      //       key: "chainlinkVRFCoodinatorABI",
      //       type: "string",
      //     },
      //   ],
      // },

      {
        title: "Rewards",
        value: "rewards",
        data: [
          {
            key: "firstPlayBonus",
            type: "number",
          },
          {
            key: "firstPlayInChallengeReward",
            type: "number",
          },
          {
            key: "followBurnGhostOnXReward",
            type: "number",
          },
          {
            key: "joinBurnGhostChannelReward",
            type: "number",
          },

          {
            key: "challengeWinnerReward",
            type: "number",
          },
          {
            key: "completeTenChallengeReward",
            type: "number",
          },
          {
            key: "joinBurnGhostCommunityReward",
            type: "number",
          },
          {
            key: "rePostBurnGhostTweetReward",
            type: "number",
          },
          {
            key: "unrevealedChestReward",
            type: "number",
          },
          {
            key: "referrerUserReward",
            type: "number",
          },
          {
            key: "referredUserReward",
            type: "number",
          },
        ],
      },
    ],

    withdrawUsdtEmails: [],
    fraudDetectionEmails: [],

    //  Earn reward Settings
    firstPlayBonus: null,
    firstPlayInChallengeReward: null,
    followBurnGhostOnXReward: null,
    joinBurnGhostChannelReward: null,
    challengeWinnerReward: null,
    completeTenChallengeReward: null,
    referrerUserReward: null,
    referredUserReward: null,
    joinBurnGhostCommunityReward: null,
    rePostBurnGhostTweetReward: null,
    unrevealedChestReward: null,
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.getTelegram.loading && this.props.getTelegram.loading !== prevProps.getTelegram.loading)
      this.ref.current.complete();

    if (this.props.getTelegram.loading && this.props.getTelegram.loading !== prevProps.getTelegram.loading)
      this.ref.current.continuousStart();
    if (this.state.showAddModal !== prevState.showAddModal && !this.state.showAddModal) {
      setTimeout(() => {
        this.setState({ editData: null, editId: null });
      }, 500);
    }
  };
  componentDidMount = () => {
    this.setState({ loadingMain: true });
    this.loadData();
  };
  loadData = (callback) => {
    this.props.loadSettings((res) => {
      const settings = res.data.data;

      //  Email Settings
      const withdrawUsdtEmails = settings?.withdrawUsdtEmails ?? [];
      const fraudDetectionEmails = settings?.fraudDetectionEmails ?? [];

      //  Chain Link Settings
      const chainlinkVRFCoodinatorAddress = settings?.length > 0 ? settings[0]?.chainlinkVRFCoodinatorAddress : "";
      const chainlinkSubscriptionId = settings?.length > 0 ? settings[0]?.chainlinkSubscriptionId : null;
      const chainlinkVRFCoodinatorABI = settings?.length > 0 ? settings[0]?.chainlinkVRFCoodinatorABI : "";

      //  Earn reward Settings
      const firstPlayBonus = settings?.firstPlayBonus ?? null;
      const firstPlayInChallengeReward = settings?.firstPlayInChallengeReward ?? null;
      const followBurnGhostOnXReward = settings?.followBurnGhostOnXReward ?? null;
      const joinBurnGhostChannelReward = settings?.joinBurnGhostChannelReward ?? null;
      const challengeWinnerReward = settings?.challengeWinnerReward ?? null;
      const completeTenChallengeReward = settings?.completeTenChallengeReward ?? null;
      const referrerUserReward = settings?.referrerUserReward ?? null;
      const referredUserReward = settings?.referredUserReward ?? null;
      const joinBurnGhostCommunityReward = settings?.joinBurnGhostCommunityReward ?? null;
      const rePostBurnGhostTweetReward = settings?.rePostBurnGhostTweetReward ?? null;
      const unrevealedChestReward = settings?.unrevealedChestReward ?? null;
      this.setState({
        loadingMain: false,

        //  Misc Settings
        withdrawUsdtEmails,
        fraudDetectionEmails,
        chainlinkSubscriptionId,
        chainlinkVRFCoodinatorABI,

        firstPlayBonus,
        firstPlayInChallengeReward,
        followBurnGhostOnXReward,
        joinBurnGhostChannelReward,
        challengeWinnerReward,
        completeTenChallengeReward,
        referrerUserReward,
        referredUserReward,
        joinBurnGhostCommunityReward,
        rePostBurnGhostTweetReward,
        unrevealedChestReward,
      });
      if (callback) callback();
    });
  };
  //  Toggle modals
  toggleAddModal = (e) => {
    if (e) e.preventDefault();
    this.setState({ showAddModal: !this.state.showAddModal });
  };
  toggleAddMiscSettings = (e) => {
    if (e) e.preventDefault();
    this.setState({ showMiscSettingsFlyer: !this.state.showMiscSettingsFlyer });
  };
  toggleAddDailyGameChallengeSettings = (e) => {
    if (e) e.preventDefault();
    this.setState({
      showDailyGameChallengeSettingsFlyer: !this.state.showDailyGameChallengeSettingsFlyer,
    });
  };
  render() {
    const { mainSettings, modalTitle, miscSettingData } = this.state ?? [];
    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="telegram-mini-app-misc" />

          {!this.state.loadingMain && (
            <div className="main-content-wrapper position-relative">
              <div className="mcw-header d-flex align-items-center">
                <h1>Misc Settings</h1>
              </div>
              <div className="fwc-wrapper">
                <div className="fwc-body">
                  <div className="table-responsive">
                    <table className="table large-table">
                      <thead>
                        <tr>
                          <th style={{ width: 75, textAlign: "center" }}>Actions</th>
                          <th>Title</th>
                        </tr>
                      </thead>
                      <tbody>
                        {miscSettingData.map((interval, index) => (
                          <tr>
                            <td>
                              <Dropdown className=" custom-dropdown">
                                <Dropdown.Toggle
                                  id="tdDropdownOne"
                                  className="custom-dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <img src={carrentIcon} alt="" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className={`dropdown-menu-start `} aria-labelledby="tdDropdownOne">
                                  <Dropdown.Item
                                    className="dropdown-item"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      //editData object contains interval data keys as keys and state values as values
                                      const editData = interval.data?.reduce(
                                        (obj, { key }) => ({
                                          ...obj,
                                          [key]: this.state[key],
                                        }),
                                        {},
                                      );
                                      this.setState(
                                        {
                                          editData,
                                          modalTitle: interval,
                                          editId: index,
                                        },
                                        () => {
                                          interval.value === "dailyGameChallenge"
                                            ? this.toggleAddDailyGameChallengeSettings()
                                            : this.toggleAddMiscSettings();
                                        },
                                      );
                                    }}
                                  >
                                    Edit
                                  </Dropdown.Item>{" "}
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                            <td>
                              <Link
                                className="two-line-ellipsis"
                                to={"#"}
                                onClick={(e) => {
                                  e.preventDefault();
                                  //editData object contains interval data keys as keys and state values as values
                                  const editData = interval?.data?.reduce(
                                    (obj, { key }) => ({
                                      ...obj,
                                      [key]: this.state[key],
                                    }),
                                    {},
                                  );
                                  this.setState(
                                    {
                                      editData,
                                      modalTitle: interval,
                                      editId: index,
                                    },
                                    () => {
                                      interval.value === "dailyGameChallenge"
                                        ? this.toggleAddDailyGameChallengeSettings()
                                        : this.toggleAddMiscSettings();
                                    },
                                  );
                                }}
                              >
                                {interval.title}
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <AddMiscSettings
          showModal={this.state.showMiscSettingsFlyer}
          toggleModal={this.toggleAddMiscSettings}
          edit={this.state.editData}
          editId={this.state.editId}
          modalTitle={modalTitle}
          setResponse={(val) => {
            this.setState({ [modalTitle?.value]: val });
          }}
          loadSettings={this.loadData}
        />

        {(this.state.showAddModal ||
          this.state.showHistory ||
          this.state.showMiscSettingsFlyer ||
          this.state.showDailyGameChallengeSettingsFlyer) && (
          <div
            className="modal-backdrop show fade"
            onClick={
              this.state.showHistory
                ? this.toggleHistoryFlyer
                : this.state.showMiscSettingsFlyer
                ? this.toggleAddMiscSettings
                : this.state.showDailyGameChallengeSettingsFlyer
                ? this.toggleAddDailyGameChallengeSettings
                : this.toggleAddModal
            }
          ></div>
        )}
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadSettings: (callback) => dispatch(loadTelegramSettings(callback)),
});
const mapStateToProps = (state) => ({
  getTelegram: getTelegram(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Listing));
