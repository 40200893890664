import Joi from "joi-browser";
import _ from "lodash";
import { createRef } from "react";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//  Images
import arrowIcon1 from "include/images/arrow-down-icon-1.svg";
import arrowIcon2 from "include/images/arrow-down-icon-2.svg";
import arrowIcon from "include/images/arrow-down-icon.svg";
import carrentIcon from "include/images/carrent-icon.svg";
import filterIcon from "include/images/filter-icon.svg";
import emptyIcon from "include/images/nrf.svg";
import userIcon from "include/images/user.png";
//  Components
import Form from "common/form/form";
import NextButton from "common/form/submitButton";
import Header from "common/header";
import Loader from "common/loader";
import SideBar from "common/sidebar";
import closeIcon from "include/images/close-icon.svg";
import moment from "moment";
import {
  getTelegram,
  loadTelegramUsers,
  updateTelegramUserStatus,
  updateTelegramUserStatusReceived,
} from "store/telegram";
import ListingActivitiesFlyer from "./listingActivitiesFlyer";
import AlertError from "common/alert/alertError";
import { toast } from "react-toastify";
import AlertSuccess from "common/alert/alertSuccess";
import SuspendUserFlyer from "./suspendUserFlyer";
import AlertModal from "common/alert/alertModal";
import SelectSearch from "common/form/selectSearch";

class UserListing extends Form {
  ref = createRef(null);

  state = {
    data: {
      userName: "",
      firstName: "",
      lastName: "",
      points: "",
      usdtBalance: "",
      referredBy: null,
    },
    showSuspendUser: false,
    showUnsuspendModal: false,
    sorting: { sort: "createdAt", order: "desc" },
    errors: {},
    loading: false,
    showFilters: false,
    page: 1,
    appliedFilters: {},
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.getTelegram.loading && this.props.getTelegram.loading !== prevProps.getTelegram.loading)
      this.ref.current.complete();

    if (this.props.getTelegram.loading && this.props.getTelegram.loading !== prevProps.getTelegram.loading)
      this.ref.current.continuousStart();
  };

  componentDidMount = () => {
    this.setState({ loading: true });
    const params = { sort: "createdAt", order: "desc", page: 1 };
    this.props.loadTelegramUsers(params, (res) => {
      if (res?.status === 200) {
        this.setState({
          loading: false,
        });
      } else this.setState({ loading: false });
    });
  };

  //  Filters
  searchFilters = () => {
    this.setState({ loadingFilters: true });
    const data = { ...this.state.data };
    const sorting = { ...this.state.sorting };

    const payLoad = {
      userName: data?.userName,
      firstName: data?.firstName,
      lastName: data?.lastName,
      referredBy: data?.referredBy?.value,
      sort: sorting?.sort,
      order: sorting?.order,
    };
    if (!_.isEmpty(data.status)) {
      payLoad.status = data.status.value;
    }

    var params = _.pickBy(payLoad, function (value, key) {
      return value !== "";
    });
    params.page = this.state.page;

    this.props.loadTelegramUsers(params, (userRes) => {
      if (userRes?.status === 200) {
        const data = { ...this.state.data };
        const appliedFilters = {};
        Object.keys(this.state.data).forEach((key) => {
          if (!_.isEmpty(data[key])) {
            appliedFilters[key] = data[key];
          }
        });
        this.setState({
          appliedFilters,
          loading: false,
          loadingMore: false,
          loadingFilters: false,
        });
      } else this.setState({ loading: false, loadingFilters: false });
    });
  };
  resetFilters = (e) => {
    e.preventDefault();
    this.setState(
      {
        data: {
          userName: "",
          firstName: "",
          lastName: "",
          referredBy: null,
        },
        page: 1,
      },
      () => this.searchFilters(),
    );
  };

  onChange = (e) => {
    const data = this.state.data;
    const errors = this.state.errors;
    data[e.name] = e.currentTarget;
    delete errors[e.name];
    this.setState({ data, errors });
  };

  toggleActivitiesFlyer = (e) => {
    if (e) e.preventDefault();
    this.setState({ showActivitiesList: !this.state.showActivitiesList });
  };

  schema = {
    userName: Joi.string().allow(""),
    referredBy: Joi.object().allow(null),
    points: Joi.number().allow(""),
    usdtBalance: Joi.number().allow(""),
    firstName: Joi.string().allow(""),
    lastName: Joi.string().allow(""),
  };
  sort = (e, type) => {
    if (e) e.preventDefault();
    const sorting = { ...this.state.sorting };
    if (type === 1) {
      if (sorting?.sort === "points" && sorting?.order === "desc") {
        sorting.order = "asc";
      } else {
        sorting.order = "desc";
      }
      sorting.sort = "points";
    } else if (type === 2) {
      if (sorting?.sort === "usdtBalance" && sorting?.order === "desc") {
        sorting.order = "asc";
      } else {
        sorting.order = "desc";
      }
      sorting.sort = "usdtBalance";
    } else if (type === 3) {
      if (sorting?.sort === "gearBoxNft" && sorting?.order === "desc") {
        sorting.order = "asc";
      } else {
        sorting.order = "desc";
      }
      sorting.sort = "gearBoxNft";
    } else if (type === 4) {
      if (sorting?.sort === "createdAt" && sorting?.order === "desc") {
        sorting.order = "asc";
      } else {
        sorting.order = "desc";
      }
      sorting.sort = "createdAt";
    }
    this.setState({ sorting, page: 1 }, () => {
      this.searchFilters();
    });
  };
  suspendModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showSuspendUser: !this.state.showSuspendUser });
  };
  alertModalToggle = (e) => {
    if (e) e.preventDefault();
    this.setState({ showUnsuspendModal: !this.state.showUnsuspendModal });
  };
  handleSuspendUser = (e, status, reason, callback) => {
    if (e) e.preventDefault();
    this.props.updateTelegramUserStatus(
      {
        status,
        reason: reason,
      },
      this.state.user?._id,
      (userRes) => {
        if (userRes.status === 200) {
          toast(<AlertSuccess message="Information Saved!" />, {
            containerId: 1,
          });
          this.props.updateTelegramUserStatusReceived({
            _id: this.state.user._id,
            status,
            reason,
          });
          callback();
        } else {
          toast(<AlertError message={userRes.data && userRes.data.message} />, {
            containerId: 1,
          });
          callback();
        }
      },
    );
  };
  render() {
    const { telegramUsers, totalCount, filteredCount } = this.props.getTelegram;

    return (
      <>
        <Header history={this.props.history} />
        <Loader loaderRef={this.ref} />
        <div className="page-wrapper add-dev-server-wrapper">
          <SideBar page="telegram-mini-app-users" />
          <div className="main-content-wrapper position-relative">
            <div className="mcw-header d-flex align-items-center">
              <h1>Telegram Users {totalCount > 0 && `(${totalCount})`}</h1>
              <div className="filter-menu-box d-flex align-items-center ms-auto">
                <div className="fmb-box">
                  <a
                    href="#!"
                    className="filter-link-box"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ showFilters: !this.state.showFilters });
                    }}
                  >
                    <img src={filterIcon} /> <span>Filters</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="fwc-wrapper">
              {this.state.showFilters && (
                <div className="fwc-head ">
                  <div className="fwc-inner">
                    <ul className="filter-mode-list question-filter-mode-list d-flex flex-wrap align-items-center">
                      <li className="flex-fill">
                        <div className="fml-box">{this.renderInput("userName", "Username")}</div>
                      </li>{" "}
                      <li className="flex-fill">
                        <div className="fml-box">{this.renderInput("points", "Points Greater Than", "", "number")}</div>
                      </li>
                      <li className="flex-fill">
                        <div className="fml-box">{this.renderInput("usdtBalance", "USDT Greater Than")}</div>
                      </li>{" "}
                      <li className="flex-fill">
                        <div className="fml-box">
                          <label className="form-label">Referred</label>
                          <SelectSearch
                            name="referredBy"
                            options={[
                              {
                                name: "Yes",
                                value: true,
                              },
                              {
                                name: "No",
                                value: false,
                              },
                            ]}
                            label=""
                            value={this.state.data.referredBy}
                            error={this.state.errors.referredBy}
                            onChange={this.onChange}
                          />
                        </div>
                      </li>
                    </ul>
                    <div className="fwc-btn d-flex align-items-center justify-content-end">
                      <NextButton
                        handleSubmit={this.resetFilters}
                        classData="btn-text pointer"
                        label="Reset"
                        loading={this.state.loadingReset}
                      />
                      <NextButton
                        handleSubmit={() => {
                          this.setState(
                            {
                              page: 1,
                            },
                            () => this.searchFilters(),
                          );
                        }}
                        classData="btn btn-primary btn-sm"
                        label="Search"
                        loading={this.state.loadingFilters}
                      />
                    </div>
                  </div>{" "}
                  {!_.isEmpty(this.state.appliedFilters) && (
                    <div className="filter-tag-container d-flex align-items-center ">
                      <div className="fwc-left">
                        <div className="filter-tag-list">
                          <p>filter applied :</p>
                          {Object.keys(this.state.appliedFilters).map((key) => (
                            <div className="filter-tag-item">
                              <span className="camelCase">
                                {key === "userName"
                                  ? "Username"
                                  : key === "points"
                                  ? "Points Greater Than"
                                  : key === "usdtBalance"
                                  ? "USDT Greater Than"
                                  : key === "referredBy"
                                  ? "Referred"
                                  : key}
                              </span>{" "}
                              :{" "}
                              {key === "referredBy"
                                ? this.state.appliedFilters[key].name
                                : this.state.appliedFilters[key]}
                              {key !== "status" && (
                                <a
                                  href="#!"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setState(
                                      {
                                        data: { ...this.state.data, [key]: "" },
                                        page: 1,
                                      },
                                      this.searchFilters,
                                    );
                                  }}
                                >
                                  <img src={closeIcon} alt="" />
                                </a>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className="">
                {" "}
                <div className="fwc-body">
                  {!this.state.loading && (
                    <div className="table-responsive">
                      {telegramUsers?.length === 0 ? (
                        <div className="fwc-body">
                          <div className="no-record-found-container">
                            <div className="nfr-box">
                              <img src={emptyIcon} alt="No Record Found" />
                              <div className="nrf-text">
                                <h5>No Records Found!</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <table className="table large-table">
                          <thead>
                            <tr>
                              <th style={{ textAlign: "center" }}>Actions</th>
                              <th>Users</th>
                              <th width={150}>
                                Points
                                <a href="#!" onClick={(e) => this.sort(e, 1)}>
                                  <img
                                    src={
                                      this.state.sorting?.sort === "points"
                                        ? this.state.sorting?.order === "desc"
                                          ? arrowIcon1
                                          : arrowIcon2
                                        : arrowIcon
                                    }
                                    alt=""
                                  />
                                </a>
                              </th>
                              <th width={150}>
                                USDT
                                <a href="#!" onClick={(e) => this.sort(e, 2)}>
                                  <img
                                    src={
                                      this.state.sorting?.sort === "usdtBalance"
                                        ? this.state.sorting?.order === "desc"
                                          ? arrowIcon1
                                          : arrowIcon2
                                        : arrowIcon
                                    }
                                    alt=""
                                  />
                                </a>
                              </th>
                              <th width={300}>Status</th>
                              <th width={300}>Referrer</th>
                              <th width={300}>
                                Date
                                <a href="#!" onClick={(e) => this.sort(e, 4)}>
                                  <img
                                    src={
                                      this.state.sorting?.sort === "createdAt"
                                        ? this.state.sorting?.order === "desc"
                                          ? arrowIcon1
                                          : arrowIcon2
                                        : arrowIcon
                                    }
                                    alt=""
                                  />
                                </a>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {telegramUsers &&
                              telegramUsers?.length > 0 &&
                              telegramUsers?.map((user, i) => (
                                <tr>
                                  <td>
                                    <Dropdown className=" custom-dropdown">
                                      <Dropdown.Toggle
                                        id="tdDropdownOne"
                                        className="custom-dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <img src={carrentIcon} alt="" />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className={`dropdown-menu-start `} aria-labelledby="tdDropdownOne">
                                        <Dropdown.Item
                                          className="dropdown-item"
                                          onClick={(e) => {
                                            this.setState({ user: user }, () => {
                                              this.toggleActivitiesFlyer(e);
                                            });
                                          }}
                                        >
                                          View Activities
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </td>
                                  <td>
                                    <span>
                                      <div className="td-img-group d-flex align-items-start ">
                                        <div
                                          className="td-img round-img m-right-2 pointer"
                                          onClick={(e) => {
                                            this.setState({ user: user }, () => {
                                              this.toggleActivitiesFlyer(e);
                                            });
                                          }}
                                        >
                                          {user.image ? (
                                            <img src={process.env.REACT_APP_CDN + user.image.small} alt="" />
                                          ) : (
                                            <img src={userIcon} alt="" />
                                          )}
                                        </div>

                                        <div>
                                          <a
                                            href="#!"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              this.setState({ user: user }, () => {
                                                this.toggleActivitiesFlyer(e);
                                              });
                                            }}
                                          >
                                            {user.firstName} {user.lastName}
                                          </a>
                                          <br />
                                          {user.userName}
                                        </div>
                                      </div>
                                    </span>
                                  </td>
                                  <td className="ust-tag">
                                    <Dropdown className=" custom-dropdown my-2">
                                      <Dropdown.Toggle
                                        id="tdDropdownOne"
                                        className="custom-dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        disabled
                                      >
                                        <span className={`w-100 text-center d-block arcade`}>
                                          Points: {user?.points?.toLocaleString() ?? 0}
                                        </span>
                                      </Dropdown.Toggle>
                                    </Dropdown>
                                  </td>
                                  <td className="ust-tag">
                                    <Dropdown className=" custom-dropdown my-2">
                                      <Dropdown.Toggle
                                        id="tdDropdownOne"
                                        className="custom-dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        disabled
                                      >
                                        <span className={`w-100 text-center d-block loot`}>
                                          USDT Balance: {user?.usdtBalance?.toLocaleString("en-us") ?? 0}
                                        </span>
                                      </Dropdown.Toggle>
                                    </Dropdown>
                                  </td>
                                  <td className="ust-tag">
                                    <Dropdown className="custom-dropdown my-2">
                                      <Dropdown.Toggle
                                        id="tdDropdownOne"
                                        className="custom-dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <span
                                          className={`w-100 text-center d-block ${
                                            user.status === -1 ? "suspended" : user.status === 0 ? "inactive" : "active"
                                          }`}
                                        >
                                          {user.status === -1 ? "Suspended" : user.status === 0 ? "Inactive" : "Active"}
                                        </span>
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu className={`dropdown-menu-start `} aria-labelledby="tdDropdownOne">
                                        <Dropdown.Item
                                          className="dropdown-item "
                                          onClick={(e) => {
                                            this.setState({ user: user }, () =>
                                              user.status === -1
                                                ? this.alertModalToggle(e)
                                                : this.suspendModalToggle(e),
                                            );
                                          }}
                                        >
                                          {user.status === -1 ? "Activate" : "Suspend"} User
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </td>
                                  <td>
                                    {user?.referredBy && (
                                      <span>
                                        <div className="td-img-group d-flex align-items-start ">
                                          <div className="td-img round-img m-right-2">
                                            <img
                                              src={
                                                user?.referredBy?.image
                                                  ? process.env.REACT_APP_CDN + user?.referredBy?.image?.small
                                                  : userIcon
                                              }
                                              alt=""
                                            />
                                          </div>

                                          <div>
                                            {user?.referredBy?.firstName} {user?.referredBy?.lastName}
                                            <br />
                                            {user?.referredBy?.userName}
                                          </div>
                                        </div>
                                      </span>
                                    )}
                                  </td>
                                  <td>{moment(user.createdAt).format("MM/DD/YYYY hh:mm a")}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  )}
                  {!this.state.loading && !(this.state.page === 1 && telegramUsers?.length === 0) && (
                    <div className="page-pagination">
                      <ul className="pagination  justify-content-end">
                        <li className="page-item">
                          <a
                            class={`page-link ${this.state.page === 1 && "disabled"}`}
                            href="#!"
                            aria-label="Previous"
                            onClick={(e) => {
                              e.preventDefault();
                              if (this.state.page > 1) {
                                this.setState({ page: this.state.page - 1 }, () => this.searchFilters());
                              }
                            }}
                          >
                            <svg
                              width="8"
                              height="14"
                              viewBox="0 0 8 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M7 13L1 7L7 1" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </a>
                        </li>
                        <li className="page-item active">
                          <a
                            className="page-link"
                            href="#!"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            {this.state.page}
                          </a>
                        </li>
                        {telegramUsers?.length >= 50 && (
                          <li className="page-item">
                            <a
                              className="page-link"
                              href="#!"
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({ page: this.state.page + 1 }, () => this.searchFilters());
                              }}
                            >
                              {this.state.page + 1}
                            </a>
                          </li>
                        )}
                        <li className="page-item">
                          <a
                            class={`page-link ${telegramUsers?.length < 50 && "disabled"}`}
                            href="#!"
                            aria-label="Next"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ page: this.state.page + 1 }, () => this.searchFilters());
                            }}
                          >
                            <svg
                              width="8"
                              height="14"
                              viewBox="0 0 8 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M1 13L7 7L1 1" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        <ListingActivitiesFlyer
          showModal={this.state.showActivitiesList}
          toggleModal={this.toggleActivitiesFlyer}
          user={this.state.user}
        />
        <SuspendUserFlyer
          showModal={this.state.showSuspendUser}
          toggleModal={this.suspendModalToggle}
          handleSuspendUser={this.handleSuspendUser}
        />
        <AlertModal
          alertModalToggle={this.alertModalToggle}
          alertModalShow={this.state.showUnsuspendModal}
          title="Alert"
          description="Are you sure you want to activate the user?"
          alertType="Yes"
          onPress={(e) => {
            this.setState({ loadingUnsuspend: true });
            this.handleSuspendUser(e, 1, "", () => {
              this.alertModalToggle();
              this.setState({ loadingUnsuspend: false });
            });
          }}
          loading={this.state.loadingUnsuspend}
        />
        {(this.state.showActivitiesList || this.state.showSuspendUser) && (
          <div
            className="modal-backdrop z-index-1000 show fade"
            onClick={this.state.showSuspendUser ? this.suspendModalToggle : this.toggleActivitiesFlyer}
          ></div>
        )}
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadTelegramUsers: (pramas, callback) => dispatch(loadTelegramUsers(pramas, callback)),
  updateTelegramUserStatus: (data, id, callback) => dispatch(updateTelegramUserStatus(data, id, callback)),
  updateTelegramUserStatusReceived: (payLoad) => dispatch(updateTelegramUserStatusReceived(payLoad)),
});
const mapStateToProps = (state) => ({
  getTelegram: getTelegram(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserListing));
