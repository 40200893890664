import Joi from "joi-browser";
import { Offcanvas } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { getSettings } from "store/settings";

//  Images
import closeIcon from "include/images/dark-close-icon.svg";

//  Components
import AlertError from "common/alert/alertError";
import AlertSuccess from "common/alert/alertSuccess";
import Form from "common/form/form";
import NextButton from "common/form/submitButton";
import { addTelegramSetting } from "store/telegram";

class AddFlyer extends Form {
  state = {
    loading: false,
    data: {
      type: "email",
      withdrawUsdtEmails: "",
      fraudDetectionEmails: "",

      firstPlayBonus: "",
      firstPlayInChallengeReward: "",
      followBurnGhostOnXReward: "",
      joinBurnGhostChannelReward: "",
      challengeWinnerReward: "",
      completeTenChallengeReward: "",
      joinBurnGhostCommunityReward: "",
      rePostBurnGhostTweetReward: "",
      unrevealedChestReward: "",
      referredUserReward: "",
      referrerUserReward: "",
    },
    errors: {},
    loadingCredits: false,
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal !== prevProps.showModal) {
      this.clearData();
      const edit = this.props.edit;
      const type = this.props.modalTitle?.value;
      if (this.props.showModal) {
        const data = structuredClone(this.state.data);
        data.type = this.props.modalTitle?.value;
        if (type === "email") {
          data.withdrawUsdtEmails = edit?.withdrawUsdtEmails?.join(",") ?? "";
          data.fraudDetectionEmails = edit?.fraudDetectionEmails?.join(",") ?? "";
        } else if (type === "rewards") {
          data.firstPlayBonus = edit?.firstPlayBonus;
          data.firstPlayInChallengeReward = edit?.firstPlayInChallengeReward;
          data.followBurnGhostOnXReward = edit?.followBurnGhostOnXReward;
          data.joinBurnGhostChannelReward = edit?.joinBurnGhostChannelReward;
          data.challengeWinnerReward = edit?.challengeWinnerReward;
          data.completeTenChallengeReward = edit?.completeTenChallengeReward;
          data.referrerUserReward = edit?.referrerUserReward;
          data.referredUserReward = edit?.referredUserReward;
          data.joinBurnGhostCommunityReward = edit?.joinBurnGhostCommunityReward;
          data.rePostBurnGhostTweetReward = edit?.rePostBurnGhostTweetReward;
          data.unrevealedChestReward = edit?.unrevealedChestReward;
        }
        this.setState({ data });
      }
    }
  };
  doSubmit = (e) => {
    this.setState({ loadingCredits: true });
    const data = structuredClone(this.state.data);
    const payLoad = {};
    if (data.type === "email") {
      payLoad.withdrawUsdtEmails = data.withdrawUsdtEmails
        ?.split(",")
        ?.map((el) => el.trim())
        ?.filter((el) => el !== "");
      payLoad.fraudDetectionEmails = data.fraudDetectionEmails
        ?.split(",")
        ?.map((el) => el.trim())
        ?.filter((el) => el !== "");
    } else if (data.type === "chainLink") {
      payLoad.chainlinkVRFCoodinatorAddress = data.chainlinkVRFCoodinatorAddress;
      payLoad.chainlinkSubscriptionId = parseInt(data.chainlinkSubscriptionId);
      payLoad.chainlinkVRFCoodinatorABI = data.chainlinkVRFCoodinatorABI;
    } else if (data.type === "rewards") {
      payLoad.firstPlayBonus = parseInt(data.firstPlayBonus);
      payLoad.firstPlayInChallengeReward = parseInt(data.firstPlayInChallengeReward);
      payLoad.followBurnGhostOnXReward = parseInt(data.followBurnGhostOnXReward);
      payLoad.joinBurnGhostChannelReward = parseInt(data.joinBurnGhostChannelReward);
      payLoad.challengeWinnerReward = parseInt(data.challengeWinnerReward);
      payLoad.completeTenChallengeReward = parseInt(data.completeTenChallengeReward);
      payLoad.referrerUserReward = parseInt(data.referrerUserReward);
      payLoad.referredUserReward = parseInt(data.referredUserReward);
      payLoad.joinBurnGhostCommunityReward = parseInt(data.joinBurnGhostCommunityReward);
      payLoad.rePostBurnGhostTweetReward = parseInt(data.rePostBurnGhostTweetReward);
      payLoad.unrevealedChestReward = parseInt(data.unrevealedChestReward);
    }
    this.props.addTelegramSetting(payLoad, (settingsResponse) => {
      if (settingsResponse.status === 200) {
        toast(<AlertSuccess message="Information Saved" />, {
          containerId: 1,
        });
        const settings = settingsResponse.data.data;
        this.props.loadSettings(() => {
          this.props.toggleModal();
          this.clearData();
          this.setState({
            loadingCredits: false,
          });
        });
      } else {
        this.setState({
          loadingCredits: false,
        });
        toast(<AlertError message={settingsResponse.data && settingsResponse.data.message} />, {
          containerId: 1,
        });
      }
    });
  };
  schema = {
    type: Joi.string().allow(""),
    withdrawUsdtEmails: Joi.when("type", {
      is: "email",
      then: Joi.string().required(),
      otherwise: Joi.string().allow(""),
    }),
    fraudDetectionEmails: Joi.when("type", {
      is: "email",
      then: Joi.string().required(),
      otherwise: Joi.string().allow(""),
    }),
    firstPlayBonus: Joi.when("type", {
      is: "rewards",
      then: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
      otherwise: Joi.alternatives().try(Joi.string(), Joi.number()).allow(["", null]),
    }),
    firstPlayInChallengeReward: Joi.when("type", {
      is: "rewards",
      then: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
      otherwise: Joi.alternatives().try(Joi.string(), Joi.number()).allow(["", null]),
    }),
    followBurnGhostOnXReward: Joi.when("type", {
      is: "rewards",
      then: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
      otherwise: Joi.alternatives().try(Joi.string(), Joi.number()).allow(["", null]),
    }),
    joinBurnGhostChannelReward: Joi.when("type", {
      is: "rewards",
      then: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
      otherwise: Joi.alternatives().try(Joi.string(), Joi.number()).allow(["", null]),
    }),
    challengeWinnerReward: Joi.when("type", {
      is: "rewards",
      then: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
      otherwise: Joi.alternatives().try(Joi.string(), Joi.number()).allow(["", null]),
    }),
    completeTenChallengeReward: Joi.when("type", {
      is: "rewards",
      then: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
      otherwise: Joi.alternatives().try(Joi.string(), Joi.number()).allow(["", null]),
    }),
    referrerUserReward: Joi.when("type", {
      is: "rewards",
      then: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
      otherwise: Joi.alternatives().try(Joi.string(), Joi.number()).allow(["", null]),
    }),
    referredUserReward: Joi.when("type", {
      is: "rewards",
      then: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
      otherwise: Joi.alternatives().try(Joi.string(), Joi.number()).allow(["", null]),
    }),
    joinBurnGhostCommunityReward: Joi.when("type", {
      is: "rewards",
      then: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
      otherwise: Joi.alternatives().try(Joi.string(), Joi.number()).allow(["", null]),
    }),
    rePostBurnGhostTweetReward: Joi.when("type", {
      is: "rewards",
      then: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
      otherwise: Joi.alternatives().try(Joi.string(), Joi.number()).allow(["", null]),
    }),
    unrevealedChestReward: Joi.when("type", {
      is: "rewards",
      then: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
      otherwise: Joi.alternatives().try(Joi.string(), Joi.number()).allow(["", null]),
    }),
  };
  clearData = () => {
    this.setState({
      loading: false,
      data: {
        type: "email",
        withdrawUsdtEmails: "",
        fraudDetectionEmails: "",

        firstPlayBonus: "",
        firstPlayInChallengeReward: "",
        followBurnGhostOnXReward: "",
        joinBurnGhostChannelReward: "",
        challengeWinnerReward: "",
        completeTenChallengeReward: "",
        referrerUserReward: "",
        referredUserReward: "",
        joinBurnGhostCommunityReward: "",
        rePostBurnGhostTweetReward: "",
        unrevealedChestReward: "",
      },
      errors: {},
      loadingCredits: false,
    });
  };
  changeArrayText = (e) => {
    const data = structuredClone(this.state.data);
    const errors = structuredClone(this.state.errors);
    data[e.target.name] = e.target.value;
    delete errors[e.target.name];
    this.setState({ data });
  };

  render() {
    const data = structuredClone(this.state.data);
    console.log("errors:", this.state.errors);
    const type = this.state.data.type;
    return (
      <Offcanvas
        className={"offcanvas offcanvas-end custom-offcanvas-pannel half-width-modal"}
        show={this.props.showModal}
        onHide={this.props.toggleModal}
        id="rewardPannel"
        placement="right"
        name="rewardPannel"
        backdrop={true}
      >
        <Offcanvas.Header>
          <h5>{this.props.modalTitle?.title}</h5>
          <div
            className="btn_close pointer"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={this.props.toggleModal}
          >
            <img src={closeIcon} alt="" />
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-check-outer">
            <div className="new-form-group">
              <table className="table large-table flyer-table">
                {type === "email" ? (
                  <tbody>
                    <tr className="flyer-table">
                      <td className="vertical-middle">Withdraw USDT Emails</td>
                      <td>{this.renderInput("withdrawUsdtEmails", "")}</td>
                    </tr>
                    <tr className="flyer-table">
                      <td className="vertical-middle">Fraud Detection Emails </td>
                      <td>{this.renderInput("fraudDetectionEmails", "")}</td>
                    </tr>
                  </tbody>
                ) : type === "rewards" ? (
                  <tbody>
                    <tr className="flyer-table">
                      <td className="vertical-middle">First Time Game Play Bonus</td>
                      <td>{this.renderInput("firstPlayBonus", "", "", "number")}</td>
                    </tr>
                    <tr className="flyer-table">
                      <td className="vertical-middle">First Play In Challenge Bonus</td>
                      <td>{this.renderInput("firstPlayInChallengeReward", "", "", "number")}</td>
                    </tr>
                    <tr className="flyer-table">
                      <td className="vertical-middle">Follow us on X Reward</td>
                      <td>{this.renderInput("followBurnGhostOnXReward", "", "", "number")}</td>
                    </tr>{" "}
                    <tr className="flyer-table">
                      <td className="vertical-middle">Join our Channel Reward</td>
                      <td>{this.renderInput("joinBurnGhostChannelReward", "", "", "number")}</td>
                    </tr>{" "}
                    <tr className="flyer-table">
                      <td className="vertical-middle">Game Challenge Winner Bonus</td>
                      <td>{this.renderInput("challengeWinnerReward", "", "", "number")}</td>
                    </tr>
                    <tr className="flyer-table">
                      <td className="vertical-middle">Complete 10 Challenges Reward</td>
                      <td>{this.renderInput("completeTenChallengeReward", "", "", "number")}</td>
                    </tr>{" "}
                    <tr className="flyer-table">
                      <td className="vertical-middle">Join the community Reward</td>
                      <td>{this.renderInput("joinBurnGhostCommunityReward", "", "", "number")}</td>
                    </tr>
                    <tr className="flyer-table">
                      <td className="vertical-middle">Repost a BG Tweet Reward</td>
                      <td>{this.renderInput("rePostBurnGhostTweetReward", "", "", "number")}</td>
                    </tr>{" "}
                    <tr className="flyer-table">
                      <td className="vertical-middle">Unrevealed Chest Reward</td>
                      <td>{this.renderInput("unrevealedChestReward", "", "", "number")}</td>
                    </tr>
                    <tr className="flyer-table">
                      <td className="vertical-middle">Referrer User Reward</td>
                      <td>{this.renderInput("referrerUserReward", "", "", "number")}</td>
                    </tr>
                    <tr className="flyer-table">
                      <td className="vertical-middle">Referred User Reward</td>
                      <td>{this.renderInput("referredUserReward", "", "", "number")}</td>
                    </tr>
                  </tbody>
                ) : (
                  <></>
                )}
              </table>
            </div>
          </div>
        </Offcanvas.Body>
        <div className="offcanvas-footer">
          <NextButton
            classData="btn btn-default btn-block h-100"
            label="Save"
            loading={this.state.loadingCredits}
            handleSubmit={this.handleSubmit}
          />
        </div>
      </Offcanvas>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addTelegramSetting: (data, callback) => dispatch(addTelegramSetting(data, callback)),
});
const mapStateToProps = (state) => ({
  getSettings: getSettings(state),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddFlyer));
